import { Box, Grid, TextField, Button } from "@mui/material";
import axios from "axios";
import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";

//add validation and notification after sending email
export default function ContactForm() {
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");

  const { t } = useTranslation();

  const formTitle = t("contact.formTitle");
  const formSecondary = t("contact.formSecondary");
  const prenume = t("contact.prenume");
  const nume = t("contact.nume");
  const subiect = t("contact.subiect");
  const mesaj = t("contact.mesaj");
  const butonTrimite = t("contact.butonTrimite");

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    sendEmail(message).then(() => {
      alert("Sent!");
    });
  };

  async function sendEmail(text: string) {
    try {
      await axios.post(
        "/api/post/send-email",
        { text: text, subject: subject },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <Box
      sx={{
        float: "right",
        width: "50%",
        padding: "20px",
        boxSizing: "border-box",
        h1: {
          color: "#1976d2",
        },
      }}
    >
      <h1>{formTitle}</h1>
      <h2>{formSecondary}</h2>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container rowSpacing={5} sx={{ marginTop: "112px" }}>
          <Grid item xs={12}>
            <TextField
              id="first-name"
              label={prenume}
              variant="outlined"
              sx={{
                backgroundColor: "white",
                border: "6px solid",
                borderRadius: "20px",
                width: "73%",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="last-name"
              label={nume}
              variant="outlined"
              sx={{
                backgroundColor: "white",
                border: "6px solid",
                borderRadius: "20px",
                width: "73%",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="subject"
              label={subiect}
              variant="outlined"
              value={subject}
              onChange={(event) => setSubject(event.target.value)}
              sx={{
                backgroundColor: "white",
                border: "6px solid",
                borderRadius: "20px",
                width: "73%",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="message"
              label={mesaj}
              variant="outlined"
              multiline
              rows={16}
              value={message}
              onChange={(event) => setMessage(event.target.value)}
              sx={{
                backgroundColor: "white",
                width: "73%",
                border: "6px solid",
                borderRadius: "20px",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained">
              {butonTrimite}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

import { Avatar, Box } from "@mui/material";
import Catalin from "./images/catalin.jpg";
import certificate1 from "./images/certificate1.jpg";
import certificate2 from "./images/certificate2.jpg";
import certificate3 from "./images/certificate3.jpg";
import { useTranslation } from "react-i18next";

export default function Despre() {
  const { t } = useTranslation();

  const title = t("despre.title");
  const firstText = t("despre.firstText");
  const position = t("despre.position");
  const certificate = t("despre.certificate");

  return (
    <Box
      sx={{
        margin: "auto",
        width: "50%",
        position: "relative",
        minHeight: "1000px",
        minWidth: "580px",
        marginTop: "50px",
      }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          width: "100%",
          height: "100%",
          position: "absolute",
          borderRadius: "25px",
          color: "black",
          textAlign: "center",
          h3: {
            padding: "20px",
          },
        }}
      >
        <h1>{title}</h1>
        <h3>{firstText}</h3>
        <h1>{position}</h1>
        <Avatar
          alt="Catalin"
          src={Catalin}
          sx={{ width: 200, height: 200, margin: "0 auto", marginTop: "50px" }}
        />
        <h3>{firstText}</h3>
        <h1>{certificate}</h1>
        <img
          alt="first-certificate"
          src={certificate1}
          height="225"
          width="200"
          style={{ marginRight: "10px" }}
        />
        <img
          alt="second-certificate"
          src={certificate2}
          height="225"
          width="200"
          style={{ marginRight: "10px" }}
        />
        <img
          alt="third-certificate"
          src={certificate3}
          height="225"
          width="200"
        />
      </Box>
    </Box>
  );
}

import antifrictiune from "./images/antifrictiune.jpeg";
import ferocrom from "./images/ferocrom.jpg";
import ferocromLC from "./images/ferocromLC.jpg";
import feromangan from "./images/feromangan.jpg";
import feromolibden from "./images/feromolibden.jpg";
import feroniobium from "./images/feroniobium.jpg";
import ferosilicomangan from "./images/ferosilicomangan.jpg";
import ferovanadium from "./images/ferovanadium.jpg";
import ferowolfram from "./images/ferowolfram.jpg";
import fonta from "./images/fonta.jpg";
import staniu from "./images/staniu.jpeg";
import zinc from "./images/zinc.jpg";
import {
  FeromanganTable,
  FerocromTable,
  AAFTable,
  FerocromLCTable,
  FeromolibdenTable,
  FeroniobiumTable,
  FerosilicomanganTable,
  FerovanadiumTable,
  FerowolframTable,
  FontaTable,
  ZincTable,
} from "./tables";

export type Metal = {
  name: string;
  imgSrc: string;
  table?: JSX.Element;
  granulation?: string;
  forma?: string;
  ambalare?: string;
  composition?: string;
};

export const data: Metal[] = [
  {
    name: "Feromangan(FeMn) 75",
    imgSrc: feromangan,
    table: FeromanganTable,
    granulation: "30-80mm (10-30mm max 10%, 30-80 min 90%)",
  },
  {
    name: "Ferocrom(FeCr) 60",
    imgSrc: ferocrom,
    granulation: "30-80mm (10-30 mm max 10%, 30-80mm min 90%)",
    table: FerocromTable,
  },
  {
    name: "Ferocrom(FeCr) LC 60",
    imgSrc: ferocromLC,
    granulation: "10-50mm min 90%",
    table: FerocromLCTable,
  },
  {
    name: "Feroniobium(FeNb) 65",
    imgSrc: feroniobium,
    granulation: "35-50mm min 90%",
    table: FeroniobiumTable,
  },
  {
    name: "Ferosilicomangan(FeSiMn)",
    imgSrc: ferosilicomangan,
    granulation: "30-80mm (10-30 mm max 10%, 30-80 mm min 90%)",
    table: FerosilicomanganTable,
  },
  {
    name: "Feromolibden(FeMo)",
    imgSrc: feromolibden,
    granulation: "10-50mm min 90%",
    table: FeromolibdenTable,
  },
  {
    name: "Ferovanadiu(FeV) 80",
    imgSrc: ferovanadium,
    granulation: "10-50mm min 90%",
    table: FerovanadiumTable,
  },
  {
    name: "Ferowolfram(FeW) 80",
    imgSrc: ferowolfram,
    granulation: "10-50mm",
    table: FerowolframTable,
  },
  {
    name: "Zinc(Zn)",
    imgSrc: zinc,
    composition: "Conform SR-EN 1179:2006",
    forma: "lingouri",
    table: ZincTable,
  },
  {
    name: "Staniu(Sn)",
    imgSrc: staniu,
    composition: "Conform EN 610-1995 cu Staniu 99.99%",
    forma: "lingouri, baghete, sarma",
  },
  {
    name: "Aliaj Antifrictiune",
    imgSrc: antifrictiune,
    composition: "Conform STAS 202-80",
    table: AAFTable,
  },
  {
    name: "Fonta",
    imgSrc: fonta,
    ambalare: "vrac, Big-bags de 1000 kg sau conform cerintei clientului.",
    table: FontaTable,
  },
];

export function getMetal(name: string): Metal | undefined {
  for (let i = 0; i < data.length; i++) {
    if (data[i].name === name) {
      return data[i];
    }
  }
}

import { useParams } from "react-router-dom";
import ProductPage from "./components/ProductPage";

export default function Produs() {
  const pid = useParams();

  if (pid) {
    return <ProductPage metalName={pid as { pid: string }} />;
  } else {
    return <p>Not Found</p>;
  }
}

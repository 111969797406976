import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          acasa: {
            title: "Some of ",
            title2: "products...",
            span: "our ",
            desc: "Chemical Composition: ",
          },
          common: {
            acasa: "home",
            produse: "products",
            despre: "about us",
            maiMult: "More",
          },
          contact: {
            title: "INFORMATION",
            adress: "ADDRESS",
            registration: "REGISTRATION NR.",
            phone: "PHONE",
            formTitle: "CONTACT FORM",
            formSecondary: "Send us an email with your offer!",
            prenume: "Surname",
            nume: "Name",
            subiect: "Subject",
            mesaj: "Message",
            butonTrimite: "Send",
          },
          despre: {
            title: "ABOUT US",
            firstText:
              "ProfilTrade SRL is a company in Romania, with headquarters in Braila. This operates in the metal industry, being a stockholder and trader of metals. The company was founded on 20 october 2008.",
            position: "MANAGING DIRECTOR",
            certificate: "CERTIFICATES",
          },
          produs: {
            granulatie: "Granulation: ",
            forma: "Shape: ",
            ambalare: "Packaging: ",
          },
          produse: {
            title: "Our ",
            span: "products...",
            desc: "Chemical Composition: ",
          },
        },
      },
      ro: {
        translation: {
          acasa: {
            title: "Cateva ",
            title2: "produsele noastre...",
            span: "din ",
            desc: "Compozitie Chimica: ",
          },
          common: {
            acasa: "acasa",
            produse: "produse",
            despre: "despre noi",
            maiMult: "Mai Mult",
          },
          contact: {
            title: "INFORMATII",
            adress: "ADRESA",
            registration: "NR. INREGISTRARE",
            phone: "TELEFON",
            formTitle: "FORMULAR CONTACT",
            formSecondary: "Trimite-ne un email cu oferta dumneavoastra!",
            prenume: "Prenume",
            nume: "Nume",
            subiect: "Subiect",
            mesaj: "Mesaj",
            butonTrimite: "Trimite",
          },
          despre: {
            title: "DESPRE NOI",
            firstText:
              "Profiltrade SRL e o companie din Romania, cu sediul in Braila. Acesta operează în industria metalurgica fiind stockist si comerciant angro de metal. Compania a fost înființată la 20 octombrie 2008.",
            position: "DIRECTOR MANAGER",
            certificate: "CERTIFICATE",
          },
          produs: {
            granulatie: "Granulatie: ",
            forma: "Forma: ",
            ambalare: "Ambalare: ",
          },
          produse: {
            title: "Produsele ",
            span: "noastre...",
            desc: "Compozitie Chimica: ",
          },
        },
      },
    },
  });

export default i18n;

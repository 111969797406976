import * as React from "react";
import { Box, SxProps, Theme } from "@mui/material";

interface IJumbotronProps {
  sx?: SxProps<Theme> | undefined;
}

export default function Jumbotron(props: IJumbotronProps) {
  return (
    <Box sx={props.sx}>
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(40, 54, 87, 0.7)",
        }}
      >
        <Box>
          <Box
            sx={{
              textAlign: "center",
              marginTop: "150px",
              fontSize: "40px",
              letterSpacing: "2px",
              span: {
                color: "#1976d2",
              },
              h1: {
                color: "white",
              },
            }}
          >
            <h1>
              PROFIL<span>TRADE</span>
            </h1>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

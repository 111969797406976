import { useEffect, useState } from "react";
import axios from "axios";
import { Box, Card, CardContent } from "@mui/material";
import EuroIcon from "@mui/icons-material/Euro";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CurrencyPoundSharpIcon from "@mui/icons-material/CurrencyPoundSharp";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";

const CurrencyDisplayer: React.FC = () => {
  const [euro, setEuro] = useState(0);
  const [usd, setUsd] = useState(0);
  const [ron, setRon] = useState(0);
  const [pound, setPound] = useState(0);

  useEffect(() => {
    async function fetchCurrencies() {
      try {
        const res = await axios.get(
          "https://api.apilayer.com/exchangerates_data/latest",
          {
            headers: { apikey: "JJ9TgdwvbqRaoDJjNBpob789WYcATnu9" },
          }
        );
        setEuro(res.data.rates.EUR);
        setUsd(res.data.rates.USD);
        setRon(res.data.rates.RON);
        setPound(res.data.rates.GBP);
      } catch (error) {
        console.error(error);
      }
    }
    fetchCurrencies();
    const interval = setInterval(fetchCurrencies, 50000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={{ width: "12%", fontWeight: "bold", span: { color: "#1976d2" } }}>
      <Card variant="outlined">
        <CardContent sx={{ margin: "0 auto", width: "60%" }}>
          <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
            <EuroIcon sx={{ mr: "5px" }} />
            <p>
              <span>EUR</span> {euro}
            </p>
          </Box>
          <br />
          <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
            <AttachMoneyIcon sx={{ mr: "5px" }} />
            <p>
              <span>USD</span> {usd}
            </p>
          </Box>
          <br />
          <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
            <CurrencyExchangeIcon sx={{ mr: "5px" }} />
            <p>
              <span>RON</span> {ron}
            </p>
          </Box>
          <br />
          <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
            <CurrencyPoundSharpIcon sx={{ mr: "5px" }} />
            <p>
              <span>GBP</span> {pound}
            </p>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CurrencyDisplayer;

import React from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

export default function Map() {
  const { isLoaded } = useJsApiLoader({
    id: "map",
    googleMapsApiKey: "AIzaSyDmVezOU-wGJFubJokUVWpxAynvdE9DqhI",
  });
  return isLoaded ? (
    <GoogleMap
      id="map"
      mapContainerStyle={{
        height: "406px",
        width: "100%",
        borderRadius: "25px"
      }}
      zoom={16}
      center={{ lat: 45.27507827527682, lng: 27.960131720239133 }}
      clickableIcons= {false}
      options={{
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false
      }}
>
      <Marker position={{ lat: 45.27507827527682, lng: 27.960131720239133 }} />
    </GoogleMap>
  ) : (
    <div>Loading map...</div>
  );
}
import { Box, Grid } from "@mui/material";
import MetalCard from "./components/MetalCard";
import Ferovanadium from "./images/ferovanadium.jpg";
import Feromangan from "./images/feromangan.jpg";
import Ferocrom from "./images/ferocrom.jpg";
import Zinc from "./images/zinc.jpg";
import CurrencyDisplayer from "./components/CurrencyDisplayer";
import { useTranslation } from "react-i18next";

export default function Acasa() {
  const { t } = useTranslation();

  const title = t("acasa.title");
  const title2 = t("acasa.title2");
  const span = t("acasa.span");
  const desc = t("acasa.desc");

  return (
    <Box
      sx={{
        flexGrow: 1,
        margin: "auto",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ marginTop: "50px" }}>
          <Box
            sx={{
              margin: "auto",
              width: "50%",
              textAlign: "center",
              span: {
                color: "#1976d2",
              },
            }}
          >
            <h1>
              {title}
              <span>{span}</span>
              {title2}
            </h1>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              margin: "auto",
              width: "70%",
              display: "flex",
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <MetalCard
                  imgSrc={Ferovanadium}
                  title="Ferovanadium (FeV)"
                  desc={desc + "Conform SR ISO 5445-1995"}
                  href="/produse"
                />
              </Grid>
              <Grid item xs={3}>
                <MetalCard
                  imgSrc={Feromangan}
                  title="Feromangan (FeMn)"
                  desc={desc + "Conform SR ISO 5445-1995"}
                  href="/produse"
                />
              </Grid>
              <Grid item xs={3}>
                <MetalCard
                  imgSrc={Ferocrom}
                  title="Ferovanadium (FeV)"
                  desc={desc + "Conform SR ISO 5445-1995"}
                  href="/produse"
                />
              </Grid>
              <Grid item xs={3}>
                <MetalCard
                  imgSrc={Zinc}
                  title="Zinc (Zn)"
                  desc={desc + "Conform SR-EN 1179:2006"}
                  href="/produse"
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <CurrencyDisplayer />
        </Grid>
      </Grid>
    </Box>
  );
}

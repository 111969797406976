import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import TornadoIcon from "@mui/icons-material/Tornado";
import { LanguageSwitch } from "./Switch";
import { Link, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import english from "./images/english.png";
import romanian from "./images/romanian.png";


export default function Navbar() {
  const [checked, setChecked] = React.useState(false);

  const { t, i18n } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    event.target.checked === true
      ? i18n.changeLanguage("ro")
      : i18n.changeLanguage("en");
  };

  // const English = createSvgIcon(englishIcon, "English");
  //const Romanian = createSvgIcon(romanianIcon, "Romanian");

  const acasa = t("common.acasa");
  const produse = t("common.produse");
  const despre = t("common.despre");

  return (
    <AppBar position="static">
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <React.Fragment>
            <TornadoIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              PROFILTRADE
            </Typography>
          </React.Fragment>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Button
              sx={{
                my: 2,
                color: "white",
                display: "block",
              }}
            >
              <Link href={`/`} sx={{ color: "white" }}>
                {acasa}
              </Link>
            </Button>
            <Button
              sx={{
                my: 2,
                color: "white",
                display: "block",
              }}
            >
              <Link href={`/produse`} sx={{ color: "white" }}>
                {produse}
              </Link>
            </Button>
            <Button
              sx={{
                my: 2,
                color: "white",
                display: "block",
              }}
            >
              <Link href={`/despre`} sx={{ color: "white" }}>
                {despre}
              </Link>
            </Button>
            <Button
              sx={{
                my: 2,
                color: "white",
                display: "block",
              }}
            >
              <Link href={`/contact`} sx={{ color: "white" }}>
                contact
              </Link>
            </Button>
          </Box>
          <Stack direction="row" spacing={1} alignItems="center">
            <img alt="english" src={english} width={30} height={20} />

            <LanguageSwitch
              inputProps={{ "aria-label": "ant design" }}
              onChange={handleChange}
              checked={checked}
            />
            <img alt="romanian" src={romanian} width={30} height={20} />
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

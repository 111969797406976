import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { data } from "./data";
import MetalCard from "./components/MetalCard";
import { useTranslation } from "react-i18next";

export default function Produse() {
  const { t } = useTranslation();

  const title = t("produse.title");
  const span = t("produse.span");
  const desc = t("produse.desc");
  return (
    <Box
      sx={{
        margin: "auto",
        width: "50%",
        position: "relative",
        minHeight: "1070px",
        minWidth: "580px",
        marginTop: "50px",
        marginBottom: "10px",
      }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          width: "100%",
          height: "100%",
          position: "absolute",
          borderRadius: "25px",
          color: "black",
          textAlign: "center",
          span: {
            color: "#1976d2",
          },
          MetalCard: {
            padding: "10px",
          },
        }}
      >
        <h1>
          {title}
          <span>{span}</span>
        </h1>
        <Grid
          container
          spacing={2}
          sx={{ padding: "20px", margin: "0 auto", width: "100%" }}
        >
          {data.map((metal) => {
            return (
              <Grid item xs={4} key={`key_${metal.name}`}>
                <MetalCard
                  imgSrc={metal.imgSrc}
                  title={metal.name}
                  desc={desc + "Conform SR ISO 5445-1995"}
                  href={`/produs/${metal.name}`}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
}

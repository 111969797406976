import { Box, List, ListItem, ListItemText } from "@mui/material";
import Map from "./Map";
import { useTranslation } from "react-i18next";

export default function ContactInformationCard() {


  const { t } = useTranslation();

  const title = t('contact.title');
  const adress = t('contact.adress');
  const registration = t('contact.registration');
  const phone = t('contact.phone');

  return (
    <Box
      sx={{
        span: {
          color: "#1976d2",
        },
        float: "left",
        width: "50%",
        padding: "20px",
        boxSizing: "border-box",
        h1: {
          color: "#1976d2",
        },
      }}
    >
      <h1>{title}</h1>
      <List>
        <ListItem>
          <ListItemText
            primaryTypographyProps={{
              fontSize: 28,
              fontStyle: "italic",
              fontWeight: "bold",
            }}
            secondaryTypographyProps={{
              fontSize: 20,
              fontWeight: "medium",
              color: "black"
            }}
            primary={adress}
            secondary="Braila, Str. Mihai Bravu 252"
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primaryTypographyProps={{
              fontSize: 28,
              fontStyle: "italic",
              fontWeight: "bold",
            }}
            secondaryTypographyProps={{
              color: "black",
              fontSize: 20,
              fontWeight: "medium",
            }}
            primary={registration}
            secondary="J09/545/2010"
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primaryTypographyProps={{
              fontSize: 28,
              fontStyle: "italic",
              fontWeight: "medium",
            }}
            secondaryTypographyProps={{
              color: "black",
              fontSize: 20,
              fontWeight: "medium",
            }}
            primary="C.U.I"
            secondary="RO23110444"
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primaryTypographyProps={{
              fontSize: 28,
              fontStyle: "italic",
              fontWeight: "medium",
            }}
            secondaryTypographyProps={{
              color: "black",
              fontSize: 20,
              fontWeight: "medium",
            }}
            primary={phone}
            secondary="+40757093043"
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primaryTypographyProps={{
              fontSize: 28,
              fontStyle: "italic",
              fontWeight: "medium",
            }}
            secondaryTypographyProps={{
              color: "black",
              fontSize: 20,
              fontWeight: "medium",
            }}
            primary="EMAIL"
            secondary="office@profiltrade.ro"
          />
        </ListItem>
      </List>
      <Map />
    </Box>
  );
}

import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Acasa from "./Acasa";
import Despre from "./Despre";
import Produse from "./Produse";
import Produs from "./Produs";
import Contact from "./Contact";
import Navbar from "./Navbar";
import Jumbotron from "./components/Jumbotron";
import profiltrade from "./images/profiltrade.jpg";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Jumbotron
        sx={{
          position: "relative",
          backgroundPosition: "center center",
          minHeight: "450px",
          minWidth: "580px",
          margin: "auto",
          backgroundSize: "contain",
          height: "100%",
          backgroundColor: "#AAD2DE",
          backgroundImage: `url(${profiltrade})`,
          width: "100%",
        }}
      />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Acasa />} />
          <Route path="/despre" element={<Despre />} />
          <Route path="/produse" element={<Produse />} />
          <Route path="/produs/:pid" element={<Produs />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

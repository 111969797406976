import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { getMetal } from "../data";
import { Metal } from "../data";
import { useTranslation } from "react-i18next";

interface IProductPageProps {
  metalName: {
    pid: string;
  };
}

export default function ProductPage(props: IProductPageProps) {
  const { metalName } = props;

  const { t } = useTranslation();

  const granulatie = t("produs.granulatie");
  const forma = t("produs.forma");
  const ambalare = t("produs.ambalare");

  const [metal, setMetal] = useState<Metal | undefined>({
    name: "",
    imgSrc: "",
    composition: "",
    granulation: "",
  });

  useEffect(() => {
    const temp = getMetal(metalName.pid);
    console.log(metalName);
    setMetal(temp);
  }, [metalName]);

  return (
    <Box
      sx={{
        margin: "auto",
        width: "50%",
        position: "relative",
        minHeight: "1250px",
        minWidth: "580px",
        marginTop: "50px",
      }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          width: "100%",
          height: "100%",
          position: "absolute",
          borderRadius: "25px",
          color: "black",
          textAlign: "center",
          span: {
            color: "#1976d2",
          },
        }}
      >
        <h1>{metal?.name}</h1>

        <img
          alt="metal_picture"
          src={metal?.imgSrc || ""}
          style={{
            margin: "0 auto",
            padding: "25px",
            marginTop: "5px",
            borderTop: "1px solid",
            maxWidth: "100%",
          }}
          width={800}
          height={600}
        />

        {metal?.table}
        {metal?.granulation && (
          <h1>
            <span>{granulatie}</span>
            {metal?.granulation}
          </h1>
        )}
        {metal?.forma && (
          <h1>
            <span>{forma}</span>
            {metal?.forma}
          </h1>
        )}
        {metal?.ambalare && (
          <h1>
            <span>{ambalare}</span>
            {metal?.ambalare}
          </h1>
        )}
      </Box>
    </Box>
  );
}

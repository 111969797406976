import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

export const FeromanganTable = (
  <TableContainer component={Paper} sx={{ padding: "10px" }}>
    <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
      <TableHead>
        <TableRow sx={{ border: "2px solid" }}>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Mn
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            C
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Si
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            P
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            S
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell
            sx={{
              border: "2px solid",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
            component="th"
            scope="row"
          >
            70-85%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            min 8%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 2%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.35%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.03%
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

export const FerocromTable = (
  <TableContainer component={Paper} sx={{ padding: "10px" }}>
    <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
      <TableHead>
        <TableRow sx={{ border: "2px solid" }}>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Cr
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            C
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Si
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            P
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            S
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell
            sx={{
              border: "2px solid",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
            component="th"
            scope="row"
          >
            60%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            6-8%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            3-5%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.03%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.05%
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

export const FerocromLCTable = (
  <TableContainer component={Paper} sx={{ padding: "10px" }}>
    <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
      <TableHead>
        <TableRow sx={{ border: "2px solid" }}>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Cr
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            C
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Si
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            P
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            S
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell
            sx={{
              border: "2px solid",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
            component="th"
            scope="row"
          >
            60%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            0.1-0.25%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            1.5%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.03%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.03%
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

export const FeroniobiumTable = (
  <TableContainer component={Paper} sx={{ padding: "10px" }}>
    <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
      <TableHead>
        <TableRow sx={{ border: "2px solid" }}>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Nb
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Ti
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Al
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Si
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell
            sx={{
              border: "2px solid",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
            component="th"
            scope="row"
          >
            65%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.5%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 1%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 2.5%
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

export const FerosilicomanganTable = (
  <TableContainer component={Paper} sx={{ padding: "10px" }}>
    <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
      <TableHead>
        <TableRow sx={{ border: "2px solid" }}>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Mn
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Si
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            C
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            P
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            S
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell
            sx={{
              border: "2px solid",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
            component="th"
            scope="row"
          >
            min 65%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            16-20%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 2%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.25%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.03%
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

export const FeromolibdenTable = (
  <TableContainer component={Paper} sx={{ padding: "10px" }}>
    <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
      <TableHead>
        <TableRow sx={{ border: "2px solid" }}>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Mo
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Si
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            C
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            P
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            S
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Cu
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell
            sx={{
              border: "2px solid",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
            component="th"
            scope="row"
          >
            60%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            1%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.1%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.05%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.1%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.5%
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

export const FerovanadiumTable = (
  <TableContainer component={Paper} sx={{ padding: "10px" }}>
    <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
      <TableHead>
        <TableRow sx={{ border: "2px solid" }}>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            V
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Si
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Al
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            C
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            P
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            S
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            As
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Cu
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Mn
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Ni
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell
            sx={{
              border: "2px solid",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
            component="th"
            scope="row"
          >
            80%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 2%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 1.5%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.3%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.06%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.05%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.06%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.1%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.5%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.15%
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

export const FerowolframTable = (
  <TableContainer component={Paper} sx={{ padding: "10px" }}>
    <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
      <TableHead>
        <TableRow sx={{ border: "2px solid" }}>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            W
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Si
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            C
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Mn
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Cu
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            P
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            S
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            As
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Sb
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Al
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Mo
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Sn
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell
            sx={{
              border: "2px solid",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
            component="th"
            scope="row"
          >
            70-85%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 1%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 1%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.6%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.25%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.05%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.06%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.1%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.05%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.1%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 1%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.1%
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

export const ZincTable = (
  <TableContainer component={Paper} sx={{ padding: "10px" }}>
    <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
      <TableHead>
        <TableRow sx={{ border: "2px solid" }}>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Marca
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Zn
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Pb
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Cd
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Fe
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Sn
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Cu
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Al
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell
            sx={{
              border: "2px solid",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
            component="th"
            scope="row"
          >
            Zinc R1
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            99.995%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            0.0003% max
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            0.0003% max
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            0.0002% max
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            0.0001% max
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            0.0001% max
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            0.0001% max
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            sx={{
              border: "2px solid",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
            component="th"
            scope="row"
          >
            Zinc R2
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            99.99%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            0.0005% max
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            0.0003% max
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            0.0003% max
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            0.0001% max
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            0.0002% max
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            -
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);
export const AAFTable = (
  <TableContainer component={Paper} sx={{ padding: "10px" }}>
    <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
      <TableHead>
        <TableRow sx={{ border: "2px solid" }}>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Simbol Chimic
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Y - SN 80
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Y - SN 83
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Y - SN 89
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell
            sx={{
              border: "2px solid",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
            component="th"
            scope="row"
          >
            Sn
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            79-81%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            82 - 84%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            88 - 90%
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            sx={{
              border: "2px solid",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
            component="th"
            scope="row"
          >
            Sb
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            11 - 13%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            10 - 12%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            7 - 8%
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell
            sx={{
              border: "2px solid",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
            component="th"
            scope="row"
          >
            Cu
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            5 - 7%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            5.5 - 6.5%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            3 - 4%
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            sx={{
              border: "2px solid",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
            component="th"
            scope="row"
          >
            Zn
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            0.05%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            0.05%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            0.05%
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            sx={{
              border: "2px solid",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
            component="th"
            scope="row"
          >
            Pb
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.5%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.5%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.5%
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

export const FontaTable = (
  <TableContainer component={Paper} sx={{ padding: "10px" }}>
    <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
      <TableHead>
        <TableRow sx={{ border: "2px solid" }}>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Marca
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Si
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            Mn
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            P
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            S
          </TableCell>
          <TableCell
            sx={{
              border: "2px solid",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            align="center"
          >
            C
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell
            sx={{
              border: "2px solid",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
            component="th"
            scope="row"
          >
            L1
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            3.2 - 3.6%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 1%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.08%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.05%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 3.5 - 4.5%
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            sx={{
              border: "2px solid",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
            component="th"
            scope="row"
          >
            L2
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            2.8 - 3.2%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 1%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.08%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.05%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            3.5 - 4.5%
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            sx={{
              border: "2px solid",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
            component="th"
            scope="row"
          >
            L3
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            2.4 - 2.8%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 1%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.08%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.05%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            3.5 - 4.5%
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            sx={{
              border: "2px solid",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
            component="th"
            scope="row"
          >
            L4
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            2.0 - 2.4%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 1%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.08%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.05%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            3.5 - 4.5%
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            sx={{
              border: "2px solid",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
            component="th"
            scope="row"
          >
            L5
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            1.6 - 2%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            0.4 - 1%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.1%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.05%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            3.5 - 4.5%
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            sx={{
              border: "2px solid",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
            component="th"
            scope="row"
          >
            L6
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            1.2 - 1.6%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            0.4 - 1%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.1%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.05%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            3.5 - 4.5%
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            sx={{
              border: "2px solid",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
            component="th"
            scope="row"
          >
            P1
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            0.5 - 0.9%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            0.3 - 0.6%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            0.05-0.08%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.05%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            3.5 - 4.5%
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            sx={{
              border: "2px solid",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
            component="th"
            scope="row"
          >
            P2
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            0.32 - 0.49%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            0.3 - 0.6
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            0.05 - 0.09
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.05%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            4.0 - 4.5
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            sx={{
              border: "2px solid",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
            component="th"
            scope="row"
          >
            PL1
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            0.5 - 1.2%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 1%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.08%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.05%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            3.5 - 4.5%
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            sx={{
              border: "2px solid",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
            component="th"
            scope="row"
          >
            PL2
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            0.5 - 0.9%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 1%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.08%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.05%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            3.5 - 4.5%
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            sx={{
              border: "2px solid",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
            component="th"
            scope="row"
          >
            FIP
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.8%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.1%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.08%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 0.03%
          </TableCell>
          <TableCell sx={{ border: "2px solid" }} align="center">
            max 4.5%
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

interface IMetalCardProps {
  imgSrc: string;
  title: string;
  desc: string;
  href: string;
}

export default function MetalCard(props: IMetalCardProps) {
  const { imgSrc, title, desc, href } = props;

  const { t } = useTranslation();

  const maiMult = t("common.maiMult");

  return (
    <Card
      sx={{
        "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
        transition: "transform 0.15s ease-in-out",
        textAlign: "center",
      }}
    >
      <CardMedia
        component="img"
        height="140"
        width=""
        image={imgSrc}
        alt="card image"
      />
      <CardContent>
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          sx={{ margin: "0 auto", fontWeight: "bold" }}
        >
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {desc}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          href={href}
          sx={{ margin: "0 auto", width: "50%" }}
        >
          {maiMult}
        </Button>
      </CardActions>
    </Card>
  );
}

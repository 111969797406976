import { Box } from "@mui/material";
import ContactInformationCard from "./components/ContactInformationCard";
import ContactForm from "./components/ContactForm";

export default function Contact() {
  return (
    <Box
      sx={{
        width: "80%",
        margin: "0 auto",
        textAlign: "center",
        fontFamily: "Arial, sans-serif",
        "&after": {
          content: "''",
          clear: "both",
          display: "table",
        },
        marginTop: "50px",
      }}
    >
      <ContactInformationCard />
      <ContactForm />
    </Box>
  );
}
